import { useQuery } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import { IProductFilter } from './types'
import authHeader from '../../context/services/AuthHeader'
import { PUBLIC_API_URL } from '../../context/apiconfig'
import { productKeys } from './keyFactory'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../user/keyFactory'
import { useProductContext } from '../../context/products/ProductProvider'
import { PROJECT_ID } from '../../helpers'
import history from '../../context/history'

export function useLiveSearch(search_term: string | undefined) {
  const queryClient = useQueryClient()
  const status = queryClient.getQueryData(userKeys.status()) as boolean

  return useQuery({
    queryKey: productKeys.productSearch(search_term, status),
    queryFn: async () => {
      if (search_term) {
        const response = await axios.post(
          `${PUBLIC_API_URL}products/live-search`,
          { search_term: search_term, project_id: PROJECT_ID },
        )

        return response.data
      }

      return []
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useProductBanners(filter: IProductFilter) {
  return useQuery({
    queryKey: productKeys.productBanners(filter),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.post(
        `${PUBLIC_API_URL}products/banners`,
        { ...filter, project_id: PROJECT_ID },
        headersValue,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 5 * 60 * 60 * 1000,
  })
}

export function useProductList(filter: IProductFilter) {
  const queryClient = useQueryClient()
  const status = queryClient.getQueryData(userKeys.status()) as boolean
  const { cart, pagination, setPagination, setProductsSeo } =
    useProductContext()
  const cartId = cart ? cart.id : 0
  const page = pagination ? pagination.page : 1

  return useQuery({
    queryKey: productKeys.products(filter, status, cartId, page),
    queryFn: async () => {
      if (!filter.filterReady) {
        return []
      }

      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      let response = await axios.post(
        `${PUBLIC_API_URL}products`,
        {
          ...filter,
          page: page,
          project_id: PROJECT_ID,
          current_path: history.location.pathname,
        },
        headersValue,
      )

      setPagination(response.data.meta.pagination)
      setProductsSeo(response.data.meta.seo)

      if (!filter.is_not_shoplist) {
        // updateShopListUrlParams(
        //   PRODUCT_QUERY_PAGE,
        //   response.data.meta.pagination.page,
        //   response.data.meta.pagination.page !== 1,
        //   true,
        // )
      }

      return response.data.products
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useProductDetail(products_url: string | undefined) {
  return useQuery({
    queryKey: productKeys.product(products_url),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const projectIdParam = PROJECT_ID ? `?project_id=${PROJECT_ID}` : ''
      const response = await axios.get(
        `${PUBLIC_API_URL}products/get-by-url/${products_url}${projectIdParam}`,
        headersValue,
      )

      return response.data
    },
    keepPreviousData: true,
    staleTime: 3,
  })
}
