import React from 'react'
import { Button } from 'react-bootstrap'
import { useProductContext } from '../../../context/products/ProductProvider'
import { isMobile } from '../../atoms/responsive'
import { logoutUser } from '../../../queries/user'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IUserData } from '../../../queries/user/types'
import { userKeys } from '../../../queries/user/keyFactory'
import { useUserContext } from '../../../context/user/UserProvider'
import { isImpersonate } from '../../../helpers'

const ImpersonateMessage: React.FC = () => {
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData

  const { initializeUserState } = useUserContext()
  const { initializeProductState } = useProductContext()

  const logoutAndCloseUserMutation = useMutation(logoutUser, {
    onSettled: () => {
      initializeUserState()
      initializeProductState()
      window.close()
    },
  })

  const logoutAndClose = async () => {
    logoutAndCloseUserMutation.mutate({ queryClient })
  }

  return (
    <>
      {isImpersonate() ? (
        <>
          <span className="text-black">LOGGED IN AS</span>{' '}
          <span className="fw-bold text-white fs-4">{user.name}</span>
          {!isMobile && (
            <Button onClick={logoutAndClose} className="ms-2 mb-1" size="sm">
              Logout
            </Button>
          )}
        </>
      ) : null}
    </>
  )
}

export default ImpersonateMessage
