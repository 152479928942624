import React from 'react'
import ReactDOM from 'react-dom/client'
import '@fortawesome/fontawesome-free/css/all.min.css'
import App from './App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { isDev } from './helpers/EnvDetect'
import CacheInitialization from './components/packs/Initialization'

import ReactGA from 'react-ga4'
import { userKeys } from './queries/user/keyFactory'

ReactGA.initialize('G-WGVD2F3T07')

const isDevEnv = isDev()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !isDevEnv, // default: true; false if isDevEnv for debugging
      retryOnMount: !isDevEnv,
      retry: false,
    },
  },
})
queryClient.setQueryDefaults(userKeys.user, { retry: 1 })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CacheInitialization>
        <App />
      </CacheInitialization>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  </React.StrictMode>,
)
