import { useLocation } from 'react-router-dom'
import HeaderNavBar from './HeaderNavBar'
import LogoHeader from './LogoHeader'
import { isHideGlobalHeaderPath } from '../../../helpers'
import { useEffect } from 'react'
import { useSiteContext } from '../../../context/site/SiteProvider'

const Header = () => {
  const location = useLocation()
  const {
    setMainHeaderDivHeight,
    setMobileMainHeaderDivHeight,
    getFlashSaleBanners,
    mainHeaderDivHeight,
    mobileMainHeaderDivHeight,
    isDownForMaintenance,
  } = useSiteContext()

  useEffect(() => {
    getFlashSaleBanners()
  }, [])

  window.onresize = () => {
    setMainHeaderDivHeight(mainHeaderDivHeight)
    setMobileMainHeaderDivHeight(mobileMainHeaderDivHeight)
  }

  return (
    <>
      {isHideGlobalHeaderPath(location.pathname) || isDownForMaintenance ? (
        <LogoHeader linkToHome={!isDownForMaintenance} />
      ) : (
        <HeaderNavBar />
      )}
    </>
  )
}

export default Header
