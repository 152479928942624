export const API_URL_PREFIX =
  process.env.REACT_APP_API_URL_PREFIX === undefined
    ? ''
    : process.env.REACT_APP_API_URL_PREFIX
export const AUTH_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}auth/`
export const API_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/`
export const PUBLIC_API_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}public/api/`
export const API_CUSTOMERS_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/customers/`
export const API_CART_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/carts/`
export const API_DOCUMENTS_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}api/documents/` // note: DocumentLinks includes the word 'documents' but is a separate public route w/ a token
export const PUBLIC_API_SITE_URL = `${process.env.REACT_APP_API_URL}${API_URL_PREFIX}public/api/site/`
