export const cartKeys = {
  updatePromoProducts: (subtotal: number | null) =>
  ['updatePromoProducts', subtotal] as const,
  checkCartStock: ['checkCartStock'] as const,
  getPaymentDeadlineMessages: ['getPaymentDeadlineMessages'] as const,
  getCheckoutCompleteInfo: ['getCheckoutCompleteInfo'] as const,
  getPaymentCompleteInfo: ['getPaymentCompleteInfo'] as const,
  getCouponCode: ['getCouponCode'] as const,
  getExistingQuote: ['getExistingQuote'] as const,
}

