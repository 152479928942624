import {
  SET_SHOW_QUOTES_UPDATED,
  SET_SHOW_EDIT_SHIPPING_MODAL,
} from './constants'
import { initialState } from './ShowHideProvider'
import { IShowHideAction, IShowHideState } from './types'

export default function messagesReducer(
  state: IShowHideState = initialState,
  action: IShowHideAction,
) {
  const payload = action.payload

  switch (action.type) {
    case SET_SHOW_QUOTES_UPDATED:
      return {
        ...state,
        showQuotesUpdated: payload,
      }

    case SET_SHOW_EDIT_SHIPPING_MODAL:
      return {
        ...state,
        showEditShippingModal: payload,
      }

    default:
      return state
  }
}
