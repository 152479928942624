import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  GET_DESKTOP_MENU_ITEMS,
  GET_HAMBURGER_MENU_ITEMS,
  GET_STORE_SETTINGS,
  GET_APP_INFO,
  SET_APP_VERSION_CURRENT,
  GET_AUTHORIZATION_DOC_TYPES,
  GET_INSURANCE_DOC_TYPES,
  GET_APP_SETTINGS,
  TOGGLE_PRODUCT_LIST_DETAILS,
  SET_MAIN_HEADER_DIV_HEIGHT,
  SET_MOBILE_MAIN_HEADER_DIV_HEIGHT,
  SET_FLASH_SALE_BANNER_DESKTOP,
  SET_FLASH_SALE_BANNER_MOBILE,
  SET_SHOW_FLASH_SALE_BANNER,
  SET_IS_DOWN_FOR_MAINTENANCE,
} from './constants'
import siteReducer from './SiteReducer'
import { ISiteState } from './types'
import axios, { AxiosRequestConfig } from 'axios'
import { PUBLIC_API_SITE_URL } from '../apiconfig'
import authHeader from '../services/AuthHeader'
import { IContextProviderProps } from '../../types/interfaces'
import {
  initiallySelectedStoreSettings,
  initiallySelectedAppSettings,
} from '../../constants/constants'
import { projectId } from '../../constants/db'
import { PROJECT_ID, isProline } from '../../helpers'
import { isImpersonate } from '../../helpers'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../queries/user/keyFactory'
import { useNavigate } from 'react-router-dom'

export const initialState: ISiteState = {
  desktopMenuItems: null,
  hamburgerMenuItems: null,
  storeSettings: null,
  appInfo: null,
  appVersionCurrent: true,
  authorizationDocTypes: [],
  insuranceDocTypes: [],
  appSettings: null,
  showProductListDetails: true,
  mainHeaderDivHeight: 0,
  mobileMainHeaderDivHeight: 0,
  flashSaleBannerDesktop: null,
  flashSaleBannerMobile: null,
  showFlashSaleBanner: true,
  isDownForMaintenance: false,
  getDesktopMenuItems: () => {},
  getHamburgerMenuItems: () => {},
  getStoreSettings: () => {},
  getAppInfo: () => {},
  setAppVersionCurrentStatus: () => {},
  getAuthorizationDocTypes: () => {},
  getInsuranceDocTypes: () => {},
  getAppSettings: () => {},
  toggleProductListDetails: () => {},
  setMainHeaderDivHeight: () => {},
  setMobileMainHeaderDivHeight: () => {},
  getFlashSaleBanners: () => {},
  setShowFlashSaleBanner: () => {},
  setIsDownForMaintenance: () => {},
}

export const SiteContext = createContext(initialState)

export const useSiteContext = () => useContext(SiteContext)

export const SiteProvider = (props: IContextProviderProps): JSX.Element => {
  const queryClient = useQueryClient()
  const { children } = props
  const navigate = useNavigate()
  const [state, dispatch] = useReducer(siteReducer, initialState)

  const { isDownForMaintenance } = useSiteContext()

  const { data: user, refetch: getAuthUser } = useQuery(userKeys.user)

  const getDesktopMenuItems = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const menu = isProline() ? 'pl_top_nav_desktop' : 'rw_top_nav_desktop'
    const response = await axios.get(
      `${PUBLIC_API_SITE_URL}get-menu-items/${menu}`,
      headersValue,
    )

    dispatch({
      type: GET_DESKTOP_MENU_ITEMS,
      payload: response.data,
    })
  }

  const getHamburgerMenuItems = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const menu = isProline() ? 'pl_hamburger' : 'rw_hamburger'
    const response = await axios.get(
      `${PUBLIC_API_SITE_URL}get-menu-items/${menu}`,
      headersValue,
    )

    dispatch({
      type: GET_HAMBURGER_MENU_ITEMS,
      payload: response.data,
    })
  }

  const getStoreSettings = async (storeSettings: Array<string>) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${PUBLIC_API_SITE_URL}get-store-settings`,
      storeSettings,
      headersValue,
    )

    dispatch({
      type: GET_STORE_SETTINGS,
      payload: response.data,
    })
  }

  const getAppSettings = async (appSettings: Array<string>) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${PUBLIC_API_SITE_URL}get-app-settings`,
      appSettings,
      headersValue,
    )

    dispatch({
      type: GET_APP_SETTINGS,
      payload: response.data,
    })
  }

  const getAppInfo = async (projectId: number) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    let detectedDownForMaintenance = false
    const response = await axios
      .post(
        `${PUBLIC_API_SITE_URL}get-app-info`,
        {
          project_id: projectId,
        },
        headersValue,
      )
      .catch((error) => {
        console.log('getAppInfo Error', error)
        // if (error?.response?.status === 503) {
        setIsDownForMaintenance(true)
        detectedDownForMaintenance = true
        navigate('/down-for-maintenance')
        // }
      })

    if (!detectedDownForMaintenance) {
      setIsDownForMaintenance(false)
    }

    const data = response?.data[0]
    const storedAppVersion = localStorage.getItem('version')
    if (storedAppVersion === null) {
      localStorage.setItem('version', data.app_version.toString())
    } else {
      if (storedAppVersion !== data.app_version) {
        setAppVersionCurrentStatus(false) // triggers the modal
      }
    }

    if (storedAppVersion !== data.app_version) {
      dispatch({
        type: GET_APP_INFO,
        payload: data,
      })
    }
  }

  const setAppVersionCurrentStatus = async (
    appVersionCurrentStatus: boolean,
  ) => {
    if (appVersionCurrentStatus) {
      // will set to "true" if user clicks "ok" on modal,
      // and remove the version from localStorage
      localStorage.removeItem('version')
    }
    dispatch({
      type: SET_APP_VERSION_CURRENT,
      payload: appVersionCurrentStatus,
    })
  }

  const getAuthorizationDocTypes = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${PUBLIC_API_SITE_URL}get-document-dropdown-options/pro_use_auth_types`,
      headersValue,
    )

    dispatch({
      type: GET_AUTHORIZATION_DOC_TYPES,
      payload: response.data,
    })
  }

  const getInsuranceDocTypes = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${PUBLIC_API_SITE_URL}get-document-dropdown-options/insurance_doc_types`,
      headersValue,
    )

    dispatch({
      type: GET_INSURANCE_DOC_TYPES,
      payload: response.data,
    })
  }

  const toggleProductListDetails = async () => {
    dispatch({
      type: TOGGLE_PRODUCT_LIST_DETAILS,
      payload: null,
    })
  }

  const setMainHeaderDivHeight = (height: number) => {
    dispatch({
      type: SET_MAIN_HEADER_DIV_HEIGHT,
      payload: height,
    })
  }

  const setMobileMainHeaderDivHeight = async (height: number) => {
    dispatch({
      type: SET_MOBILE_MAIN_HEADER_DIV_HEIGHT,
      payload: height,
    })
  }

  const getFlashSaleBanners = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${PUBLIC_API_SITE_URL}get-flash-sale-banners?project_id=${PROJECT_ID}`,
      headersValue,
    )

    dispatch({
      type: SET_FLASH_SALE_BANNER_DESKTOP,
      payload: response.data.desktop,
    })
    dispatch({
      type: SET_FLASH_SALE_BANNER_MOBILE,
      payload: response.data.mobile,
    })
  }

  const setShowFlashSaleBanner = async (showFlashSaleBanner: boolean) => {
    dispatch({
      type: SET_SHOW_FLASH_SALE_BANNER,
      payload: showFlashSaleBanner,
    })
  }

  const setIsDownForMaintenance = async (isDownForMaintenance: boolean) => {
    dispatch({
      type: SET_IS_DOWN_FOR_MAINTENANCE,
      payload: isDownForMaintenance,
    })
  }

  useEffect(() => {
    getAppSettings(initiallySelectedAppSettings)
    getStoreSettings(initiallySelectedStoreSettings)
    getFlashSaleBanners()

    const intervalId = setInterval(() => {
      getAppInfo(projectId)
      getFlashSaleBanners()
      getStoreSettings(initiallySelectedStoreSettings)
    }, 5 * 60 * 1000) // 5 minutes

    //old-school js variable;  useState had scope issues inside the setInverval
    let isImpersonating = false
    const setIsImpersonating = (value: boolean) => {
      isImpersonating = value
    }

    //if impersonating, keep getting the user data in case another user gets impersonated without logging out first
    const userIntervalId = setInterval(() => {
      if (isImpersonate()) {
        getAuthUser()
        setIsImpersonating(true)
      } else if (isImpersonating) {
        //get auth user one more time
        setIsImpersonating(false)
        getAuthUser()
      }
    }, 5 * 1000) // 5 seconds

    return () => {
      clearInterval(intervalId)
      clearInterval(userIntervalId)
    }
  }, []) // this useEffect with functions from this Provider file may seem out of place, but can't run in App.tsx bc the bc the ____Provider needs to be initialized in App first.

  const value = {
    ...state,
    desktopMenuItems: state.desktopMenuItems,
    hamburgerMenuItems: state.hamburgerMenuItems,
    storeSettings: state.storeSettings,
    appInfo: state.appInfo,
    appVersionCurrent: state.appVersionCurrent,
    authorizationDocTypes: state.authorizationDocTypes,
    insuranceDocTypes: state.insuranceDocTypes,
    appSettings: state.appSettings,
    showProductListDetails: state.showProductListDetails,
    mainHeaderDivHeight: state.mainHeaderDivHeight,
    mobileMainHeaderDivHeight: state.mobileMainHeaderDivHeight,
    flashSaleBannerDesktop: state.flashSaleBannerDesktop,
    flashSaleBannerMobile: state.flashSaleBannerMobile,
    showFlashSaleBanner: state.showFlashSaleBanner,
    isDownForMaintenance: state.isDownForMaintenance,
    getDesktopMenuItems,
    getHamburgerMenuItems,
    getStoreSettings,
    getAppInfo,
    setAppVersionCurrentStatus,
    getAuthorizationDocTypes,
    getInsuranceDocTypes,
    getAppSettings,
    toggleProductListDetails,
    setMainHeaderDivHeight,
    setMobileMainHeaderDivHeight,
    getFlashSaleBanners,
    setShowFlashSaleBanner,
    setIsDownForMaintenance,
  }

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}
