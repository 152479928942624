import React from 'react'
import { useSiteContext } from '../../../context/site/SiteProvider'

const HeaderSpacerMobile: React.FC = () => {
  const { mobileMainHeaderDivHeight } = useSiteContext()

  return <div style={{ height: `${mobileMainHeaderDivHeight}px` }} />
}

export default HeaderSpacerMobile
