import React, { useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Button,
  Navbar,
  Nav,
  Row,
  NavDropdown,
  Offcanvas,
  DropdownButton,
  Spinner,
} from 'react-bootstrap'
import CdnImg from '../Common/CdnImg'
import HeaderSearchBar from '../HeaderSearchBar'
import BottomHeader from './BottomHeader'
import RwIconTextLink from '../../molecules/RwIconTextLink'
import RwIcon from '../../molecules/RwIcon'
import { default as CartLink } from '../Common/IconLink'
import { useProductContext } from '../../../context/products/ProductProvider'
import { generalBsBreakpoint } from '../../../constants/constants'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { ICustomerData, IUserData } from '../../../queries/user/types'
import { userKeys } from '../../../queries/user/keyFactory'
import { useUserContext } from '../../../context/user/UserProvider'
import { isImpersonate } from '../../../helpers'
import FlashSaleBanner from '../Common/FlashSaleBanner'
import AccountDropdownItems from '../Account/AccountDropdownItems'
import ImpersonateMessage from './ImpersonateMessage'
import PriceListDropdown from './PriceListDropdown'
import DesktopHeaderItem from '../../molecules/DesktopHeaderItem'

const headerNavBarBreakpoint = generalBsBreakpoint

interface IDesktopMainHeaderDiv {
  brandLogoPath: string
  handleLogout: () => void
}

const DesktopMainHeaderDiv: React.FC<IDesktopMainHeaderDiv> = ({
  brandLogoPath,
  handleLogout,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const [showDesktopMenuItems, setShowDesktopMenuItems] =
    React.useState<boolean>(true)

  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const status = queryClient.getQueryData(userKeys.status()) as boolean
  const customer = queryClient.getQueryData(
    userKeys.customer(),
  ) as ICustomerData

  const { isDarkMode } = useUserContext()
  const { cartSummary, cartUpdating } = useProductContext()
  const {
    desktopMenuItems,
    hamburgerMenuItems,
    appSettings,
    setMainHeaderDivHeight,
    showFlashSaleBanner,
    flashSaleBannerDesktop,
  } = useSiteContext()

  const clientHeightRef = ref.current ? ref.current.clientHeight : 0

  useEffect(() => {
    const clientHeight = ref.current ? ref.current.clientHeight : 0
    const mainHeaderDivHeight = clientHeight | 0
    setMainHeaderDivHeight(mainHeaderDivHeight)
  }, [clientHeightRef, showFlashSaleBanner, flashSaleBannerDesktop])

  const location = useLocation()

  return (
    <div
      ref={ref}
      className={`border-bottom fixed-top ${isDarkMode ? 'dm-bg' : 'bg-white'}`}
    >
      <FlashSaleBanner />
      <div>
        <Navbar
          bg={isImpersonate() ? 'warning' : ''}
          expand={headerNavBarBreakpoint}
          className="py-0 my-0"
        >
          {/* this Logo displays on Breakpoint and larger */}
          <Navbar.Brand
            as={Link}
            to="/"
            className={`py-0 my-0 d-none d-${headerNavBarBreakpoint}-block `}
            key="Navbar Brand"
            style={{ minHeight: '72px' }} // this is to keep the height of the Navbar consistent across projects, accounting for diff between Spirit and Pro Line logos
          >
            <CdnImg
              path={brandLogoPath}
              alt="logo"
              width="150"
              key="Logo"
              className="ps-2 pt-2"
            />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${headerNavBarBreakpoint}`}
          />

          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${headerNavBarBreakpoint}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${headerNavBarBreakpoint}`}
                className="text-primary fs-5"
              >
                {/* Spirit of '76 */}
              </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end text-end"
              >
                <Nav className="me-auto justify-content-end p-0">
                  {desktopMenuItems && showDesktopMenuItems ? (
                    (desktopMenuItems as unknown as any[]).map(
                      (item: any, index: any) => (
                        <NavDropdown
                          title={
                            <span className="text-body fs-5 my-auto">
                              {item.title}
                            </span>
                          }
                          id={item.title + '_nav_dropdown'}
                          className=""
                          key={item.title}
                        >
                          {item.children.map((data: any) => (
                            <DesktopHeaderItem
                              key={data.title}
                              text={data.title}
                              to={data.link_to}
                              isNewWindow={data.is_link_new_window}
                              icon={data.icon}
                            />
                          ))}
                        </NavDropdown>
                      ),
                    )
                  ) : (
                    <></>
                  )}
                </Nav>

                {/* Note: change "status" to see different menus */}
                {!status ? (
                  <Nav className="justify-content-end">
                    {/* this HeaderSearchBar displays when screen size headerNavBarBreakpoint OR BIGGER  */}
                    <div
                      className={`d-none d-${headerNavBarBreakpoint}-block search-bar-component-padding`}
                    >
                      <HeaderSearchBar
                        showDesktopMenuItems={showDesktopMenuItems}
                        setShowDesktopMenuItems={setShowDesktopMenuItems}
                      />
                    </div>
                    {location.pathname !== '/login' && (
                      <Link to="/login" className="px-4 ">
                        <Button
                          variant={
                            isDarkMode ? 'dm-proline-primary' : 'primary'
                          }
                          className={`${isDarkMode ? 'border-white' : ''}`}
                        >
                          <RwIcon icon="UserIcon" /> Login
                        </Button>
                      </Link>
                    )}
                  </Nav>
                ) : (
                  <>
                    {/* this HeaderSearchBar displays when screen size headerNavBarBreakpoint OR BIGGER  */}
                    <div
                      className={`d-none d-${headerNavBarBreakpoint}-block search-bar-component-padding`}
                    >
                      <HeaderSearchBar
                        showDesktopMenuItems={showDesktopMenuItems}
                        setShowDesktopMenuItems={setShowDesktopMenuItems}
                      />
                    </div>

                    <Nav className="justify-content-end  ps-3">
                      <div className="d-inline-block">
                        <Navbar.Toggle
                          aria-controls={`offcanvasNavbar-expand-${headerNavBarBreakpoint}`}
                        >
                          {' '}
                          <RwIcon icon="KeyIcon" />
                        </Navbar.Toggle>
                        <DropdownButton
                          title={
                            <span
                              className={`${
                                isDarkMode ? 'text-light' : 'text-dark'
                              }`}
                            >
                              {user?.first} <RwIcon icon="UserIcon" />
                            </span>
                          }
                          id="user_nav_dropdown"
                          align="end"
                          className={`no-focus-dropdown-button`}
                          variant={isDarkMode ? 'dm-bg' : 'white'}
                        >
                          <>
                            <h5 className="p-1">My Account</h5>
                            <AccountDropdownItems />
                            <RwIconTextLink
                              containerClassProps=""
                              textProps="Sign Out"
                              onClickProps={handleLogout}
                              icon={<RwIcon icon="SignOutIcon" />}
                            />
                          </>
                        </DropdownButton>
                      </div>
                    </Nav>
                  </>
                )}
              </Navbar.Collapse>
            </Offcanvas.Body>
          </Navbar.Offcanvas>

          {status ? (
            <Nav.Item className="pe-5 ps-1">
              <div style={{ width: '30px' }}>
                {cartUpdating ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  <CartLink
                    linkTo="/cart"
                    fontAwesomeIcon="fa-shopping-cart"
                    badgeBsTextColor="white"
                    badgeBsBgColor="accent"
                    badgeDisplayNumber={cartSummary?.cart_item_count}
                    iconBsColor={isDarkMode ? 'light' : 'primary'}
                  />
                )}
              </div>
            </Nav.Item>
          ) : (
            <></>
          )}
        </Navbar>
      </div>

      <Row className={isImpersonate() ? 'bg-warning' : ''}>
        {status ? (
          <>
            <div className="col-2 ps-2"></div>
            <div className="col-6 ps-2 text-center fs-5">
              <ImpersonateMessage />
            </div>
            <div className="col-4 ps-2 text-end" style={{ height: '24px' }}>
              <div className="d-flex align-items-center justify-content-end">
                {!!customer?.special_order_enabled && (
                  <div className="pe-4">Special Order Pricing</div>
                )}
                <NavDropdown
                  title={
                    <span
                      className={`${
                        isDarkMode ? '' : 'text-dark'
                      } text-capitalize`}
                    >
                      {customer?.price_levels_name} Price List
                    </span>
                  }
                  id="user_nav_dropdown"
                  className="float-end"
                >
                  <PriceListDropdown customer={customer} />
                </NavDropdown>
                {/* //todo: price list should come from logged-in user context  */}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-7 ps-2"></div>
            <div className="col-5 text-end pe-4" style={{ height: '24px' }}>
              <div>
                <Link to="/register">Create account</Link> /{' '}
                <Link to="/login">Login</Link> to view pricing.
              </div>
            </div>
          </>
        )}
      </Row>

      <div>
        <BottomHeader hamburgerMenuItems={hamburgerMenuItems} />
      </div>
    </div>
  )
}

export default DesktopMainHeaderDiv
