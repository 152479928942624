import React from 'react'
import { useSiteContext } from '../../../../context/site/SiteProvider'
import { Desktop, Mobile } from '../../../atoms/responsive'
import FlashSaleBannerContent from '../FlashSaleBannerContent'

const FlashSaleBanner: React.FC = () => {
  const {
    flashSaleBannerDesktop,
    flashSaleBannerMobile,
    setShowFlashSaleBanner,
    showFlashSaleBanner,
  } = useSiteContext()

  const onClick = () => {
    setShowFlashSaleBanner(false)
  }

  return (
    <div>
      {showFlashSaleBanner ? (
        <>
          <Desktop>
            <FlashSaleBannerContent
              banner={flashSaleBannerDesktop}
              handleClick={onClick}
            />
          </Desktop>
          <Mobile>
            <FlashSaleBannerContent
              banner={flashSaleBannerMobile}
              handleClick={onClick}
            />
          </Mobile>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default FlashSaleBanner
