import React, { useState } from 'react'
import { Formik } from 'formik'
import FormGroup from 'react-bootstrap/FormGroup'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useNavigate, createSearchParams } from 'react-router-dom'
import RwIcon from '../../molecules/RwIcon'
import useDebounce from '../../../hooks/useDebounce'
import { useLiveSearch } from '../../../queries/products'
import { DEFAULT_DEBOUNCE_DELAY } from '../../../helpers'
import CdnImg from '../Common/CdnImg'
import { ProductListData } from '../../../context/products/types'
import history from '../../../context/history'
import { SHOP_LIST_PATH } from '../../../constants/constants'
import { useProductContext } from '../../../context/products/ProductProvider'
import { useUserContext } from '../../../context/user/UserProvider'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { Desktop, Mobile } from '../../atoms/responsive'

export interface HeaderSearchBarProps {
  showDesktopMenuItems?: boolean
  setShowDesktopMenuItems?: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderSearchBar: React.FC<HeaderSearchBarProps> = (
  props: HeaderSearchBarProps,
) => {
  const [searchTermInState, setSearchTermInState] = useState<string>('')
  const [showResults, setShowResults] = React.useState<boolean>(false)
  const { isDarkMode } = useUserContext()
  const debouncedSearchTerm = useDebounce(
    searchTermInState,
    DEFAULT_DEBOUNCE_DELAY,
  )
  const { setPage } = useProductContext()
  const { data: products } = useLiveSearch(debouncedSearchTerm)

  const { mainHeaderDivHeight, mobileMainHeaderDivHeight } = useSiteContext()

  const hardcodedBaseTopOffsetAdjustmentMobile = 8
  const hardcodedBaseTopOffsetAdjustmentDesktop = 65
  const hardcodedBackupTopOffsetMobile = 70
  const hardcodedBackupTopOffsetDesktop = 116

  const resultsTopOffsetMobile =
    mobileMainHeaderDivHeight > 0
      ? mobileMainHeaderDivHeight - hardcodedBaseTopOffsetAdjustmentMobile
      : hardcodedBackupTopOffsetMobile
  const resultsTopOffsetDesktop =
    mainHeaderDivHeight > 0
      ? mainHeaderDivHeight - hardcodedBaseTopOffsetAdjustmentDesktop
      : hardcodedBackupTopOffsetDesktop

  const navigate = useNavigate()

  const handleFilter = (e: any) => {
    if (e.target.value.length) {
      setSearchTermInState(e.target.value)
      setShowResults(true)
    } else {
      setSearchTermInState('')
      setShowResults(false)
    }
  }

  const handleSubmit = (term: string) => {
    window.scrollTo(0, 0)
    setPage(1)
    setShowResults(false)
    setSearchTermInState('')
    const urlPath = new URLSearchParams(window.location.pathname).toString()
    const hasShoplistPath = urlPath.includes(SHOP_LIST_PATH)
    if (!hasShoplistPath) {
      navigate({
        pathname: `/${SHOP_LIST_PATH}`,
        search: `${createSearchParams({ search: term })}`,
      })
    } else {
      history.push(`/${SHOP_LIST_PATH}?${createSearchParams({ search: term })}`)
    }
  }
  const onLinkClick = (products_url: string) => {
    setShowResults(false)
    setSearchTermInState('')
    navigate(`/product/${products_url}`)
  }

  const handleFocus = (e: any) => {
    if (props.setShowDesktopMenuItems) {
      e.target.parentNode.classList.add('expanded_search_bar')
      e.target.parentNode.classList.add('expanded_search_bar_dm')

      props.setShowDesktopMenuItems(false)
      setShowResults(true)
    }
  }

  const handleBlur = (e: any) => {
    if (props.setShowDesktopMenuItems) {
      e.target.parentNode.classList.remove('expanded_search_bar')
      props.setShowDesktopMenuItems(true)
      setShowResults(false)
    }
  }

  return (
    <>
      <Formik
        onSubmit={(values: any) => {
          handleSubmit(values.searchTerm)
        }}
        initialValues={{
          searchTerm: searchTermInState,
        }}
      >
        {({ handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <FormGroup>
              <InputGroup className="mb-0 py-0 search-bar-input d-flex pe-2">
                <Form.Control
                  type="search"
                  name="searchTerm"
                  placeholder="Product Search"
                  className={`align-self-center shadow-none headersearchbar_input ${
                    isDarkMode ? 'border-light' : ''
                  }`}
                  aria-label="Search"
                  value={searchTermInState}
                  onChange={(e) => {
                    handleChange(e)
                    handleFilter(e)
                  }}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <Button
                  className={`headersearchbar_button ${
                    isDarkMode ? 'border-light' : ''
                  }`}
                  variant="outline-primary"
                  onMouseDown={() => {
                    handleSubmit(debouncedSearchTerm)
                  }}
                >
                  <RwIcon
                    icon="SearchIcon"
                    furtherIconCss={`${isDarkMode ? 'text-light' : ''}`}
                  />
                </Button>
              </InputGroup>
            </FormGroup>
          </Form>
        )}
      </Formik>

      {/* HeaderSearchBar search results list */}
      {products && !!products.length && showResults && (
        <>
          <Mobile>
            <div
              className={`search-result-data ${
                props.setShowDesktopMenuItems
                  ? props.showDesktopMenuItems
                    ? ''
                    : 'expanded_search_result_data'
                  : ''
              }`}
              style={{ top: resultsTopOffsetMobile }}
            >
              {products.map((value: ProductListData, key: number) => {
                return (
                  <div
                    className="data-item-hover"
                    key={`${value.products_model}-${key}`}
                  >
                    <div
                      onMouseDown={() => onLinkClick(value.products_url)}
                      className={`data-item ${
                        props.setShowDesktopMenuItems
                          ? props.showDesktopMenuItems
                            ? ''
                            : 'expanded_data_item'
                          : ''
                      }`}
                    >
                      {value.image_url ? (
                        <CdnImg
                          path={`_store/images/75/${value.image_url}`}
                          className="search-thumbnail"
                          alt="product"
                        />
                      ) : (
                        <></>
                      )}
                      <div className="text-truncate w-100 text-start">
                        <strong>{value.products_model}</strong> -{' '}
                        {value.products_name}
                      </div>
                      <div className="me-2 w-100 text-end">
                        {value.manufacturers_name} - {value.products_packaging}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Mobile>

          <Desktop>
            <div
              className={`search-result-data ${
                props.setShowDesktopMenuItems
                  ? props.showDesktopMenuItems
                    ? ''
                    : 'expanded_search_result_data'
                  : ''
              }`}
              style={{ top: resultsTopOffsetDesktop }}
            >
              {products.map((value: ProductListData, key: number) => {
                return (
                  <div
                    className="data-item-hover"
                    key={`${value.products_model}-${key}`}
                  >
                    <div
                      onMouseDown={() => onLinkClick(value.products_url)}
                      className={`data-item ${
                        props.setShowDesktopMenuItems
                          ? props.showDesktopMenuItems
                            ? ''
                            : 'expanded_data_item'
                          : ''
                      }`}
                    >
                      {value.image_url ? (
                        <CdnImg
                          path={`_store/images/75/${value.image_url}`}
                          className="search-thumbnail"
                          alt="product"
                        />
                      ) : (
                        <></>
                      )}
                      <div className="text-truncate w-100 text-start">
                        <strong>{value.products_model}</strong> -{' '}
                        {value.products_name}
                      </div>
                      <div className="me-2 w-100 text-end">
                        {value.manufacturers_name} - {value.products_packaging}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Desktop>
        </>
      )}
    </>
  )
}

export default HeaderSearchBar
