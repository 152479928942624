import React from 'react'
import LogoHeader from '../organisms/Header/LogoHeader'
import { useSiteContext } from '../../context/site/SiteProvider'
import { useNavigate } from 'react-router-dom'

const Page503: React.FC = () => {
  const { isDownForMaintenance } = useSiteContext()
  const navigate = useNavigate()

  if (!isDownForMaintenance) {
    navigate('/')
  }

  return (
    <div className="p-5 text-center fs-3 align-middle">
      The site is down for maintenance.
      <br />
      <br />
      Please try again later.
    </div>
  )
}

export default Page503
