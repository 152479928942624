import { QueryCache, useQuery } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import authHeader from '../../context/services/AuthHeader'
import { PUBLIC_API_URL } from '../../context/apiconfig'
import { filterKeys } from './keyFactory'
import { PROJECT_ID } from '../../helpers'
import { IProductFilterType } from './types'

const filterListsDataStaleTime = 5 * 60 * 60 * 1000 // 5hrs
// const filterListsDataStaleTime = 0 // default is 0 //? for debugging

export function useSortOptionsData(loggedIn: boolean) {
  return useQuery({
    queryKey: filterKeys.sortOptions(),
    queryFn: async () => {
      const response = await axios.get(
        `${PUBLIC_API_URL}filters/product-sort/${loggedIn}`,
      )
      return response.data
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function useCategoriesData() {
  return useQuery({
    queryKey: filterKeys.categories(),
    queryFn: async () => {
      const response = await axios.get(
        `${PUBLIC_API_URL}filters/categories?project_id=${PROJECT_ID}`,
      )

      return response.data.map((data: any) => {
        return {
          ...data,
          type: IProductFilterType.PATH,
          value: data.url,
        }
      })
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function useManufacturersData(
  search_term: string | null,
  customers_email_address: string | null,
) {
  return useQuery({
    queryKey: filterKeys.manufacturers(search_term),
    queryFn: async () => {
      const response = await axios.get(
        `${PUBLIC_API_URL}filters/manufacturers?project_id=${PROJECT_ID}`,
        {
          params: {
            search_term: search_term ?? '',
            customers_email_address: customers_email_address ?? '',
          },
        },
      )

      return response.data.map((data: any) => {
        return {
          ...data,
          type: IProductFilterType.PATH,
          value: data.url,
        }
      })
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function useProductListsData() {
  return useQuery({
    queryKey: filterKeys.productLists(),
    queryFn: async () => {
      const response = await axios.get(
        `${PUBLIC_API_URL}filters/product-lists?project_id=${PROJECT_ID}`,
      )
      return response.data.map((data: any) => {
        return {
          ...data,
          type: IProductFilterType.PATH,
          value: data.url,
        }
      })
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function useColorsData() {
  return useQuery({
    queryKey: filterKeys.colors(),
    queryFn: async () => {
      const response = await axios.get(`${PUBLIC_API_URL}filters/colors`)
      return response.data
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function useEffectsData() {
  return useQuery({
    queryKey: filterKeys.effects(),
    queryFn: async () => {
      const response = await axios.get(`${PUBLIC_API_URL}filters/effects`)
      return response.data
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function useFiringPatternsData() {
  return useQuery({
    queryKey: filterKeys.firingPatterns(),
    queryFn: async () => {
      const response = await axios.get(
        `${PUBLIC_API_URL}filters/firing-patterns`,
      )
      return response.data
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function useDebrisProfilesData() {
  return useQuery({
    queryKey: filterKeys.debrisProfiles(),
    queryFn: async () => {
      const response = await axios.get(
        `${PUBLIC_API_URL}filters/debris-profiles`,
      )
      return response.data
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function useCaliberData() {
  return useQuery({
    queryKey: filterKeys.calibers(),
    queryFn: async () => {
      const response = await axios.get(
        `${PUBLIC_API_URL}filters/caliber-filter`,
      )
      return response.data
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function useDurationsData() {
  return useQuery({
    queryKey: filterKeys.durations(),
    queryFn: async () => {
      const response = await axios.get(`${PUBLIC_API_URL}filters/durations`)
      return response.data
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function usePerformanceHeightsData() {
  return useQuery({
    queryKey: filterKeys.performanceHeights(),
    queryFn: async () => {
      const response = await axios.get(
        `${PUBLIC_API_URL}filters/performance-height-filter`,
      )
      return response.data
    },
    staleTime: filterListsDataStaleTime,
  })
}

export function usePriceFiltersData(loggedIn: boolean) {
  return useQuery({
    queryKey: filterKeys.priceFilters(loggedIn),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${PUBLIC_API_URL}filters/price-filters/${loggedIn}`,
        headersValue,
      )
      return response.data
    },
    staleTime: filterListsDataStaleTime,
  })
}
