import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import { Accordion } from 'react-bootstrap'
import {
  useCategoriesData,
  useManufacturersData,
  useProductListsData,
} from '../../../queries/filters'
import {
  PRODUCT_QUERY_CATEGORIES,
  PRODUCT_QUERY_BRANDS,
  PRODUCT_QUERY_LISTS,
  SHOP_LIST_PATH,
  PRODUCT_QUERY_SEARCH,
} from '../../../constants/constants'
import history from '../../../context/history'
import { userKeys } from '../../../queries/user/keyFactory'
import { useQueryClient } from '@tanstack/react-query'
import { IUserData } from '../../../queries/user/types'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { isMobile } from '../../atoms/responsive'
import RwIcon from '../RwIcon'
import { useUserContext } from '../../../context/user/UserProvider'
import { useProductContext } from '../../../context/products/ProductProvider'
interface ISideNavMenuProps {
  handleClick?: () => void | null
}

const SideNavMenuItems: React.FC<ISideNavMenuProps> = ({ handleClick }) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(window.location.search)
  const { hamburgerMenuItems, getDesktopMenuItems, getHamburgerMenuItems } =
    useSiteContext()
  const { isDarkMode } = useUserContext()
  const { setPage } = useProductContext()
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData

  const { data: categories } = useCategoriesData()
  const { data: manufacturers } = useManufacturersData(
    searchParams.get(PRODUCT_QUERY_SEARCH),
    user?.email,
  )
  const { data: productLists } = useProductListsData()

  useEffect(() => {
    !isMobile && getDesktopMenuItems()
    getHamburgerMenuItems()
  }, [])

  const onClickQuickLink = (e: any) => {
    setPage(1)
    const listItemId: string = e.target.id
    history.push(`${listItemId}`)

    if (handleClick) {
      handleClick()
    }
  }

  return (
    <>
      <Accordion
        flush
        className={`hamburger-menu ${isDarkMode ? 'dm-accordion-primary' : ''}`}
      >
        <div className="border-bottom border-top">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="fw-bold fs-5 m-3">Categories</div>
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Nav defaultActiveKey="/home" className="flex-column">
                  <Nav.Link
                    className={`ms-2 border-1 ${
                      isDarkMode ? 'text-light' : 'text-primary'
                    }`}
                    as={Link}
                    to={`/${SHOP_LIST_PATH}`}
                    key={`/${SHOP_LIST_PATH}`}
                    id={`/${SHOP_LIST_PATH}`}
                    onClick={(e) => onClickQuickLink(e)}
                  >
                    All Products
                  </Nav.Link>

                  {categories?.map((list: any, index: number) => (
                    <Nav.Link
                      className={`ms-2 border-top border-1 ${
                        isDarkMode ? 'text-light' : 'text-primary'
                      }`}
                      as={Link}
                      to={`/${SHOP_LIST_PATH}/c/${list.categories_url}`}
                      key={`/${SHOP_LIST_PATH}/c/${list.categories_url}`}
                      id={`/${SHOP_LIST_PATH}/c/${list.categories_url}`}
                      onClick={(e) => onClickQuickLink(e)}
                    >
                      {list.label}
                    </Nav.Link>
                  ))}
                </Nav>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </div>
      </Accordion>
      <Accordion
        flush
        className={`hamburger-menu ${isDarkMode ? 'dm-accordion-primary' : ''}`}
      >
        <div className="border-bottom border-1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="fw-bold fs-5 m-3">Brands</div>
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Nav defaultActiveKey="/home" className="flex-column">
                  {manufacturers?.map((brand: any, index: number) => (
                    <Nav.Link
                      className={`
                      ${index === 0 ? 'ms-2' : 'ms-2 border-top border-1'}
                      ${isDarkMode ? 'text-light' : 'text-primary'}`}
                      as={Link}
                      to={`/${SHOP_LIST_PATH}/b/${brand.brand_url}`}
                      key={`/${SHOP_LIST_PATH}/b/${brand.brand_url}`}
                      id={`/${SHOP_LIST_PATH}/b/${brand.brand_url}`}
                      onClick={(e) => onClickQuickLink(e)}
                      data-bs-value={brand.value}
                      data-bs-group={PRODUCT_QUERY_BRANDS}
                    >
                      {brand.label}{' '}
                      {brand.icon && (
                        <RwIcon
                          icon={`${brand.icon}${isDarkMode ? 'Dm' : ''}`}
                        />
                      )}
                    </Nav.Link>
                  ))}
                </Nav>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </div>
      </Accordion>
      <Accordion
        flush
        className={`hamburger-menu ${isDarkMode ? 'dm-accordion-primary' : ''}`}
      >
        <div className="border-bottom border-1">
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="fw-bold fs-5 m-3">Product Lists</div>
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Nav defaultActiveKey="/home" className="flex-column">
                  {productLists?.map((list: any, index: number) => (
                    <Nav.Link
                      className={`
                      ${index === 0 ? 'ms-2' : 'ms-2 border-top border-1'}
                      ${isDarkMode ? 'text-light' : 'text-primary'}`}
                      as={Link}
                      to={`/${SHOP_LIST_PATH}/l/${list.list_url}`}
                      key={`/${SHOP_LIST_PATH}/l/${list.list_url}`}
                      id={`/${SHOP_LIST_PATH}/l/${list.list_url}`}
                      onClick={(e) => onClickQuickLink(e)}
                      data-bs-value={list.value}
                      data-bs-group={PRODUCT_QUERY_LISTS}
                    >
                      {list.label}
                    </Nav.Link>
                  ))}
                  <Nav.Link
                    className={`ms-2 border-top border-1 ${
                      isDarkMode ? 'text-light' : 'text-primary'
                    }`}
                    as={Link}
                    to={`/${SHOP_LIST_PATH}`}
                    onClick={(e) => onClickQuickLink(e)}
                    data-bs-value={null}
                    data-bs-group={PRODUCT_QUERY_LISTS}
                  >
                    All Products
                  </Nav.Link>
                </Nav>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </div>
      </Accordion>
      <Accordion
        flush
        className={`hamburger-menu ${isDarkMode ? 'dm-accordion-primary' : ''}`}
      >
        {hamburgerMenuItems &&
          (hamburgerMenuItems as unknown as any[]).map(
            (item: any, index: any) => (
              <div className="border-bottom border-1" key={index}>
                <Accordion.Item eventKey={index + 4}>
                  <Accordion.Header>
                    <div className="fw-bold fs-5 m-3">{item.title}</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <Nav defaultActiveKey="/home" className="flex-column">
                        {item.children.map((data: any, index: any) => (
                          <Nav.Link
                            className={`
                            ${isDarkMode ? 'text-light' : 'text-primary'}
                            ${
                              index === 0
                                ? 'ms-2'
                                : data.link_to
                                ? 'ms-2 border-top border-1'
                                : 'fw-bold ms-2 border-top border-1'
                            }
                          `}
                            href={data.link_to}
                            target={
                              data.is_link_new_window ? '_blank' : '_self'
                            }
                            key={data.title}
                            id={data.id}
                            onClick={handleClick}
                          >
                            {data.title}
                          </Nav.Link>
                        ))}
                      </Nav>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            ),
          )}
      </Accordion>
    </>
  )
}

export default SideNavMenuItems
