import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import RwIcon from '../../molecules/RwIcon'
import { onExcelDocClick, onPricelistDocClick } from '../../../helpers'

export interface IPriceListDocuments {
  customer_pricelist_name_short: string | undefined
  customer_price_levels_id: number | undefined
}

const PriceListDocuments: React.FC<IPriceListDocuments> = (
  props: IPriceListDocuments,
) => {
  const listName = props.customer_pricelist_name_short || 'silver'
  const levelId = props.customer_price_levels_id || 0

  return (
    <>
      <h5 className="p-1">Price List Documents</h5>

      <NavDropdown.Item
        onClick={() => onPricelistDocClick(listName, 'name')}
        style={{ cursor: 'pointer' }}
        className={'m-0 mb-1 w-100 text-start'}
      >
        <RwIcon icon="PdfIcon" furtherIconCss="fa-xl" />
        <span className={'ms-2'}>Sorted by Model Name</span>
      </NavDropdown.Item>

      <NavDropdown.Item
        onClick={() => onPricelistDocClick(listName, 'model')}
        style={{ cursor: 'pointer' }}
        className={'m-0 mb-1 w-100 text-start'}
      >
        <RwIcon icon="PdfIcon" furtherIconCss="fa-xl" />
        <span className={'ms-2'}>Sorted by Model Number</span>
      </NavDropdown.Item>

      <NavDropdown.Divider />

      <h5 className="p-1">Order Forms</h5>

      <NavDropdown.Item
        onClick={() =>
          onExcelDocClick('get-excel-order-form', listName, levelId)
        }
        style={{ cursor: 'pointer' }}
        className={'m-0 mb-1 w-100 text-start'}
      >
        <RwIcon icon="ExcelIcon" furtherIconCss="fa-xl" />
        <span className={'ms-2'}>Order Form</span>
      </NavDropdown.Item>

      <NavDropdown.Item
        onClick={() =>
          onExcelDocClick('get-excel-order-form-with-upcs', listName, levelId)
        }
        style={{ cursor: 'pointer', userSelect: 'none' }}
        className={'m-0 mb-1 w-100 text-start'}
      >
        <RwIcon icon="ExcelIcon" furtherIconCss="fa-xl" />
        <span className={'ms-2'}>Order Form with UPCs</span>
      </NavDropdown.Item>
    </>
  )
}

export default PriceListDocuments
