import { Stack } from 'react-bootstrap'
import RwIcon from '../../molecules/RwIcon'
import { ISocialMedia } from '../../../queries/site/types'
import { useSocialMediaData } from '../../../queries/site'
import { useUserContext } from '../../../context/user/UserProvider'
interface ISocialMediaIconsProps {
  className?: string
}

const SocialMediaIcons: React.FC<ISocialMediaIconsProps> = ({ className }) => {
  const { data: socialMedia } = useSocialMediaData()
  const { isDarkMode } = useUserContext()

  if (!socialMedia) {
    return <></>
  }

  return (
    <Stack gap={3} direction="horizontal" className={className}>
      {socialMedia.map((socialMedia: ISocialMedia, index: number) => (
        <a key={index} href={socialMedia.url} target="_blank" rel="noreferrer">
          <div
            className={
              isDarkMode ? 'social-media-icon-dm' : 'social-media-icon'
            }
          >
            <RwIcon
              icon={socialMedia.icon}
              furtherIconCss={`fs-2 ${isDarkMode ? 'text-white' : ''}`}
            />
          </div>
        </a>
      ))}
    </Stack>
  )
}

export default SocialMediaIcons
