const development: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
const prod: boolean = process.env.REACT_APP_ENV_NAME === 'PROD'

const ENV_NAME = process.env.REACT_APP_ENV_NAME
  ? process.env.REACT_APP_ENV_NAME
  : ''

export function isDev(): boolean {
  return development
}

export function isProd(): boolean {
  return ENV_NAME == 'PROD'
}
