import React from 'react'
import { IconLinkPropInterface } from '../../../../types/interfaces'
import { Link } from 'react-router-dom'

const maxBadgeQuantity = 999

const IconLink: React.FC<IconLinkPropInterface> = (
  props: IconLinkPropInterface,
) => {
  const {
    linkTo = '',
    iconClasses = 'fa-lg position-relative pt-3',
    iconBsColor = 'light',
    fontAwesomeIcon,
    showBadge = true,
    badgeBsBgColor = 'danger',
    badgeBsTextColor = 'primary',
    badgeClasses = '',
    badgeDisplayNumber = null,
  } = props

  const iconSubComponent = () => {
    return (
      <i
        className={`fa ${fontAwesomeIcon} ${iconClasses} text-${iconBsColor} `}
      >
        {badgeDisplayNumber && showBadge ? (
          <span
            className={`badge bg-${badgeBsBgColor} text-${badgeBsTextColor} rounded-pill position-absolute bottom-0 start-50 border border-light ${badgeClasses}`}
          >
            {badgeDisplayNumber > maxBadgeQuantity
              ? `${maxBadgeQuantity}+`
              : badgeDisplayNumber}
          </span>
        ) : (
          ''
        )}
      </i>
    )
  }

  return (
    <>
      {linkTo.length ? (
        <Link to={`${linkTo}`}>{iconSubComponent()}</Link>
      ) : (
        <>{iconSubComponent()}</>
      )}
    </>
  )
}

export default IconLink
