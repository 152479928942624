import React from 'react'
import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Link, useLocation } from 'react-router-dom'
import {
  SHOP_LIST_PATH,
  generalBsBreakpoint,
  PRODUCT_QUERY_SEARCH,
} from '../../../constants/constants'
import RwIcon from '../../molecules/RwIcon'
import { Accordion } from 'react-bootstrap'

import {
  useCategoriesData,
  useManufacturersData,
  useProductListsData,
} from '../../../queries/filters'
import {
  PRODUCT_QUERY_CATEGORIES,
  PRODUCT_QUERY_BRANDS,
  PRODUCT_QUERY_LISTS,
} from '../../../constants/constants'
import history from '../../../context/history'
import { IHamburgerMenuItems } from '../../../context/site/types'
import { useQueryClient } from '@tanstack/react-query'
import { IUserData } from '../../../queries/user/types'
import { userKeys } from '../../../queries/user/keyFactory'
import SideNavMenuItems from '../../molecules/Shop/SideNavMenuItems'
import WholesaleInfo from '../../molecules/Site/WholesaleInfo'
import { useUserContext } from '../../../context/user/UserProvider'
import { isProline } from '../../../helpers'
import { BsFillMoonFill, BsSunFill } from 'react-icons/bs'
import { set } from 'lodash'
import { useProductContext } from '../../../context/products/ProductProvider'

const headerNavBarBreakpoint = generalBsBreakpoint

interface IMainNavMobileProps {
  show: boolean
  handleClose: any
  hamburgerMenuItems: IHamburgerMenuItems | null
}

const MainNavMobile: React.FC<IMainNavMobileProps> = ({
  show,
  handleClose,
  hamburgerMenuItems,
}) => {
  const location = useLocation()
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData

  const searchParams = new URLSearchParams(window.location.search)

  const mainMenuItems = (hamburgerMenuItems as unknown as any[])?.slice(0, 1)
  const hamburgerItems = (hamburgerMenuItems as unknown as any[])?.slice(1)

  const { data: categories } = useCategoriesData()
  const { data: manufacturers } = useManufacturersData(
    searchParams.get(PRODUCT_QUERY_SEARCH),
    user?.email,
  )
  const { data: productLists } = useProductListsData()
  const { isDarkMode, setDarkMode } = useUserContext()
  const { setPage } = useProductContext()
  // const onClickQuickLink = (e: any) => {
  //   const queryGroup: string = e.target.getAttribute('data-bs-group')
  //   const listItemId: string = e.target.id
  //   setPage(1)
  //   if (listItemId.length) {
  //     history.push(`${location.pathname}?${queryGroup}=${listItemId}`)
  //   } else {
  //     history.push(`${location.pathname}`)
  //   }

  //   handleClose()
  // }

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode)
  }

  return (
    <>
      <Navbar.Offcanvas
        id={`offcanvasNavbar-expand-${headerNavBarBreakpoint}`}
        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton className="border-bottom border-1">
          <Offcanvas.Title
            id={`offcanvasNavbarLabel-expand-${headerNavBarBreakpoint}`}
          >
            <Link
              to={SHOP_LIST_PATH}
              onClick={handleClose}
              className="text-decoration-none"
            >
              <div
                className={`pt-2 ms-2 ps-2 fs-5 fw-bold ${
                  isDarkMode ? 'text-light' : 'text-black'
                }`}
              >
                Wholesale Fireworks
              </div>
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="p-0">
          <SideNavMenuItems handleClick={handleClose} />
          {isProline() ? (
            <Button
              variant={isDarkMode ? 'secondary' : 'primary'}
              onClick={toggleDarkMode}
              className="ms-3 mt-3"
            >
              <span>{isDarkMode ? <BsSunFill /> : <BsFillMoonFill />}</span>
              <span className={'ms-2'}>
                {isDarkMode ? 'Disable ' : 'Enable'} Dark Mode
              </span>
            </Button>
          ) : (
            <></>
          )}

          <WholesaleInfo />
          <div className="ps-3 mt-4">
            <Link
              to={isProline() ? '/contact' : '//76fireworks.com/contact'}
              target={isProline() ? '_self' : '_blank'}
              onClick={handleClose}
            >
              Contact Us
            </Link>
          </div>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </>
  )
}

export default MainNavMobile
