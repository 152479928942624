import { useQuery } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import authHeader from '../../context/services/AuthHeader'
import {
  PUBLIC_API_SITE_URL,
  API_URL,
  API_CUSTOMERS_URL,
} from '../../context/apiconfig'
import {
  bannerKeys,
  featuredTilesKeys,
  landingPageSavedCartsKeys,
  landingPageOpenOrdersKeys,
  activeRedirectsKeys,
  socialMediaKeys,
} from './keyFactory'
import { orderStatusTypeOpenIds } from '../../constants/db'
import { PROJECT_ID } from '../../helpers'
import { ICartFilter } from '../../context/products/types'

const bannersDataStaleTime = 5 * 60 * 60 * 1000 // 5hrs
const socialMediaDataStaleTime = 5 * 60 * 60 * 1000 // 5hrs
const featuredTilesDataStaleTime = 5 * 60 * 60 * 1000 // 5hrs
const activeRedirectsDataStaleTime = 10 * 60 * 1000 // 10 minutes
// const filterListsDataStaleTime = 0 // default is 0 //? for debugging

// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export function useWholesaleBannersData() {
  return useQuery({
    queryKey: bannerKeys.loggedIn(),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${PUBLIC_API_SITE_URL}get-carousel-images/rw_landing`,
        headersValue,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: bannersDataStaleTime,
  })
}

export function useProlineBannersData() {
  return useQuery({
    queryKey: bannerKeys.loggedOut(),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${PUBLIC_API_SITE_URL}get-carousel-images/pl_landing`,
        headersValue,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: bannersDataStaleTime,
  })
}

export function useProlineAboutPageBannersData() {
  return useQuery({
    queryKey: bannerKeys.loggedOut(),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${PUBLIC_API_SITE_URL}get-carousel-images/pl-about-page-banners`,
        headersValue,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: bannersDataStaleTime,
  })
}

export function useFeaturedTilesBannersData() {
  return useQuery({
    queryKey: featuredTilesKeys.featuredTiles(),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${PUBLIC_API_SITE_URL}get-featured-tiles?project_id=${PROJECT_ID}`,
        headersValue,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: featuredTilesDataStaleTime,
  })
}

export function useSocialMediaData() {
  return useQuery({
    queryKey: socialMediaKeys.socialMedia(),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${PUBLIC_API_SITE_URL}get-social-media?project_id=${PROJECT_ID}`,
        headersValue,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: socialMediaDataStaleTime,
  })
}

export function useActiveRedirectsData() {
  return useQuery({
    queryKey: activeRedirectsKeys.activeRedirects(),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${PUBLIC_API_SITE_URL}get-active-redirects`,
        headersValue,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: activeRedirectsDataStaleTime,
  })
}

export function useSavedCartsWithItemsLandingData(limit?: number, filter?: ICartFilter) {
  return useQuery({
    queryKey: landingPageSavedCartsKeys.savedCarts(filter, limit),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.post(
        `${API_URL}carts/select-saved-carts-with-items`,
        {
          limit: limit,
          filter: filter,
          project_id: PROJECT_ID,
        },
        headersValue,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: featuredTilesDataStaleTime,
  })
}

export function useCustomerOpenOrdersLanding(
  limit?: number,
  statuses?: Array<number>,
) {
  const selectStatuses = statuses ? statuses : orderStatusTypeOpenIds
  return useQuery({
    queryKey: landingPageOpenOrdersKeys.openOrders(limit, selectStatuses),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.post(
        `${API_CUSTOMERS_URL}select-orders`,
        {
          limit: limit,
          statuses: selectStatuses,
        },
        headersValue,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: featuredTilesDataStaleTime,
  })
}
