import { useQuery } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import { API_CART_URL } from '../../context/apiconfig'
import authHeader from '../../context/services/AuthHeader'
import { cartKeys } from './keyFactory'
import { ISubmitFeedbackForm } from './types'
import toast from '../../components/molecules/RwToast'
import { useMutation } from '@tanstack/react-query'
import { PROJECT_ID } from '../../helpers'

export const useCheckCartStock = () => {
  return useQuery({
    queryKey: cartKeys.checkCartStock,
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${API_CART_URL}check-cart-stock?project_id=${PROJECT_ID}`,
        headersValue,
      )
      return response.data
    },
    enabled: false,
    staleTime: 5 * 60 * 1000,
  })
}

export const useUpdatePromoProducts = (cart_subtotal: number | null) => {
  return useQuery({
    queryKey: cartKeys.updatePromoProducts(cart_subtotal),
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.post(
        `${API_CART_URL}update-promo-products`,
        { project_id: PROJECT_ID },
        headersValue,
      )
      return response.data
    },
    enabled: false,
    staleTime: 5 * 60 * 1000,
  })
}

export const useGetExistingQuote = (shipping_quotes_id: number) => {
  return useQuery({
    queryKey: cartKeys.getExistingQuote,
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${API_CART_URL}shipping-quote/${shipping_quotes_id}`,
        headersValue,
      )
      return response.data
    },
  })
}

export const useSubmitFeedbackForm = () => {
  return useMutation({
    mutationFn: async (variables: ISubmitFeedbackForm) => {
      const { values, setIsFormSubmitted } = variables

      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.post(
        `${API_CART_URL}submit-feedback-form`,
        values,
        headersValue,
      )

      if (!response?.data?.success) {
        toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
      } else if (response?.data?.success) {
        toast.success('Feedback submitted.')
        setIsFormSubmitted(true)
      }
      return response.data
    },
  })
}

export const useGetPaymentDeadlineMessages = () => {
  return useQuery({
    queryKey: cartKeys.getPaymentDeadlineMessages,
    queryFn: async () => {
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.get(
        `${API_CART_URL}get-payment-deadline-messages?project_id=${PROJECT_ID}`,
        headersValue,
      )
      return response.data
    },
    staleTime: 5 * 60 * 1000,
  })
}
