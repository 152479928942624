import { Stack } from 'react-bootstrap'
import CdnImg from '../Common/CdnImg'

interface IPaymentIconsProps {
  className?: string
}

const PaymentIcons: React.FC<IPaymentIconsProps> = ({ className }) => {
  const imageURLs: string[] = [
    '_images/payment_icons/min/single/mastercard.svg',
    '_images/payment_icons/min/single/visa.svg',
    '_images/payment_icons/min/single/discover.svg',
    // '_images/payment_icons/min/single/amex.svg', // todo: show when we have amex % automatically calculated; will need to modify w and h below... 52x32 worked for me
  ]

  return (
    <Stack gap={2} direction="horizontal" className={className}>
      {imageURLs.map((imageURL, index) => (
        <CdnImg
          key={index}
          path={imageURL}
          style={{
            width: '70px',
            height: '46px',
            border: '1px solid #bbb',
            borderRadius: '3px',
            background: 'linear-gradient(to bottom, #ffffff 0%,#f2f2f2 100%)',
          }}
        />
      ))}
    </Stack>
  )
}

export default PaymentIcons
