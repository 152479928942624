import React from 'react'
import PaymentIcons from '../../organisms/PaymentIcons'
import {
  spiritSupportEmail,
  prolineSupportEmail,
} from '../../../constants/constants'
import { isProline } from '../../../helpers'
import { useSiteContext } from '../../../context/site/SiteProvider'

interface IWholesaleInfoProps {}

const WholesaleInfo: React.FC<IWholesaleInfoProps> = ({}) => {
  const { storeSettings } = useSiteContext()
  return (
    <div className="mt-3 ms-3">
      <div className="text-accent fw-bold">Wholesale Hours (CST)</div>
      <div
        dangerouslySetInnerHTML={{
          __html: storeSettings?.text_hours_left_side || '',
        }}
      />
      <br />
      <div className="text-accent fw-bold">Card Options:</div>
      <PaymentIcons className="mt-3" />
      <br />
      <div className="text-accent fw-bold">Contact Info</div>
      {isProline() ? (
        <div>Phone: {storeSettings?.proline_phone_number}</div>
      ) :
      (
        <div>Phone: {storeSettings?.spirit_phone_number}</div>
      )}
      <div>Email: {isProline() ? prolineSupportEmail : spiritSupportEmail}</div>
    </div>
  )
}

export default WholesaleInfo
