import React from 'react'
import { useSiteContext } from '../../../context/site/SiteProvider'

const HeaderSpacer: React.FC = () => {
  const { mainHeaderDivHeight } = useSiteContext()

  return <div style={{ height: `${mainHeaderDivHeight}px` }} />
}

export default HeaderSpacer
