import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  useGetAuthUser,
  useGetUserProfile,
  useCustomerWatcher,
  useStatusWatcher,
} from '../../../queries/user'

interface InitializationProps {
  children: React.ReactNode
}

const CacheInitialization: React.FC<InitializationProps> = ({ children }) => {
  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useGetAuthUser()

  const { data: customerWatcher } = useCustomerWatcher() // a hack, necessitated by odd query/mutation/key set-up, avoid replication
  const { data: statusWatcher } = useStatusWatcher() // a hack, necessitated by odd query/mutation/key set-up, avoid replication

  const { data: user_profile_Data } = useGetUserProfile()

  useEffect(() => {
    // console.log("'user in CacheInitialization'", userData)
  }, [userData])

  if (userIsLoading) {
    return <div>Loading...</div> // might want a modal or something.  ...don't want to go to PrivateRoutes. But also don't want whitescreen for real users.
  }

  return <>{children}</>
}

export default CacheInitialization
