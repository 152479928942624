import React from 'react'
import { NavDropdown, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IconContext } from 'react-icons'
import RwIcon from './RwIcon'
import { useUserContext } from '../../context/user/UserProvider'
import { useProductContext } from '../../context/products/ProductProvider'
import history from '../../context/history'

export interface DesktopHeaderItemProps {
  text: string
  to: string
  isNewWindow: boolean
  icon?: string
}

const DesktopHeaderItem: React.FC<DesktopHeaderItemProps> = ({
  text,
  to,
  icon,
  isNewWindow,
}) => {
  const { isDarkMode } = useUserContext()
  const { setPage } = useProductContext()

  const target = isNewWindow ? '_blank' : '_self'
  const iconNew = `${icon}${isDarkMode ? 'Dm' : ''}`

  const handleClick = () => {
    if (!isNewWindow) {
      setPage(1)
      history.push(`${to}`)
    }
  }

  return (
    <NavDropdown.Item
      to={to}
      target={target}
      as={Link}
      onClick={() => handleClick()}
    >
      {icon ? (
        <Row>
          <Col className="d-flex align-items-center">
            <span className="me-2">{text}</span>
            <IconContext.Provider value={{ className: 'react-icons' }}>
              {<RwIcon icon={iconNew} />}
            </IconContext.Provider>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col
            xs="8"
            className={`d-flex ${to ? '' : 'fw-bold'} align-items-center`}
          >
            {text}
          </Col>
        </Row>
      )}
    </NavDropdown.Item>
  )
}

export default DesktopHeaderItem
