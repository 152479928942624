import {
  GET_CUSTOMER_SHIPPING_ADDRESSES,
  GET_CUSTOMER_OPEN_ORDERS,
  GET_CUSTOMER_PREVIOUS_ORDERS,
  GET_CUSTOMER_OPEN_ORDERS_LANDING,
  GET_CUSTOMER_COMPLETE_ORDERS,
  SET_VERIFICATION_CODE,
  SET_INITIAL_URL,
  INITIALIZE_USER_STATE,
  SET_USER_DEVICE_INFO,
  SET_REQUEST_FORM_SUCCESS_TEXT,
  SET_DARK_MODE_ENABLED,
  SET_PAGE,
  SET_PAGINATION,
  SET_VERIFICATION_CODE_REQUEST,
  GET_RESET_PASSWORD_METHODS,
} from './constants'
import { initialState } from './UserProvider'
import { IUserAction, IUserState } from './types'

export default function userReducer(state: IUserState, action: IUserAction) {
  const payload = action.payload

  switch (action.type) {
    case GET_CUSTOMER_SHIPPING_ADDRESSES:
      return {
        ...state,
        shippingAddresses: payload,
      }

    case GET_CUSTOMER_OPEN_ORDERS:
      return {
        ...state,
        customerOpenOrders: payload,
      }

    case GET_CUSTOMER_OPEN_ORDERS_LANDING:
      return {
        ...state,
        customerOpenOrdersLanding: payload,
      }

    case GET_CUSTOMER_COMPLETE_ORDERS:
      return {
        ...state,
        customerCompleteOrders: payload,
      }
    case GET_CUSTOMER_PREVIOUS_ORDERS:
      return {
        ...state,
        customerPreviousOrders: payload,
      }

    case SET_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        verificationCodeRequest: payload,
      }

    case GET_RESET_PASSWORD_METHODS:
      return {
        ...state,
        resetPasswordMethods: payload,
      }

    case SET_VERIFICATION_CODE:
      return {
        ...state,
        verification_code: payload,
      }

    case SET_INITIAL_URL:
      return {
        ...state,
        initialUrl: payload,
      }

    case SET_USER_DEVICE_INFO:
      return {
        ...state,
        userDeviceInfo: {
          userBrowser: payload.name,
          userBrowserVersion: payload.version,
          userOS: payload.os,
          userType: payload.type,
        },
      }

    case SET_REQUEST_FORM_SUCCESS_TEXT:
      return {
        ...state,
        requestFormSuccessText: payload.success_message,
      }

    case INITIALIZE_USER_STATE:
      return {
        ...initialState,
      }

    case SET_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: payload,
        },
      }

    case SET_PAGINATION:
      return {
        ...state,
        pagination: payload,
      }

    case SET_DARK_MODE_ENABLED:
      return {
        ...state,
        isDarkMode: payload,
      }

    default:
      return state
  }
}
