import React, { useEffect } from 'react'
import CdnImg from '../Common/CdnImg'
import { useUserContext } from '../../../context/user/UserProvider'
import { isProline } from '../../../helpers'
import { Link } from 'react-router-dom'

export interface ILogoHeader {
  linkToHome?: boolean
}

const LogoHeader: React.FC<ILogoHeader> = ({ linkToHome = true }) => {
  const { isDarkMode, getDarkMode } = useUserContext()

  useEffect(() => {
    getDarkMode()
  }, [])

  const brandLogoPath = isProline()
    ? isDarkMode
      ? '_images/pro-line-header-logo-dm-red.png'
      : '_images/pro-line-header-logo.png'
    : '_images/LOGO-SO76-002B-2022-compressed-cropped.png'

  const logoImage = (
    <CdnImg
      path={brandLogoPath}
      alt="logo"
      width="200"
      className="ps-2 pt-2 mb-4"
    />
  )

  return (
    <div className="text-center">
      {linkToHome ? <Link to="/">{logoImage}</Link> : logoImage}
    </div>
  )
}

export default LogoHeader
