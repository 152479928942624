import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  GET_CUSTOMER_SHIPPING_ADDRESSES,
  GET_CUSTOMER_OPEN_ORDERS,
  GET_CUSTOMER_COMPLETE_ORDERS,
  GET_CUSTOMER_PREVIOUS_ORDERS,
  SET_VERIFICATION_CODE,
  SET_INITIAL_URL,
  SET_USER_DEVICE_INFO,
  INITIALIZE_USER_STATE,
  SET_REQUEST_FORM_SUCCESS_TEXT,
  SET_DARK_MODE_ENABLED,
  SET_PAGE,
  SET_PAGINATION,
  SET_VERIFICATION_CODE_REQUEST,
  GET_RESET_PASSWORD_METHODS,
} from './constants'
import {
  orderStatusTypeOpenIds,
  orderStatusTypeCompleteIds,
  orderStatusTypePreviousIds,
} from '../../constants/db'
import userReducer from './UserReducer'
import {
  IUserState,
  IChangePasswordData,
  IResetPasswordData,
  IPagination,
  IVerificationCodeRequest,
} from './types'
import axios, { AxiosRequestConfig } from 'axios'
import { AUTH_URL, API_URL, API_CUSTOMERS_URL } from '../apiconfig'
import authHeader from '../services/AuthHeader'
import { useNavigate } from 'react-router-dom'
import { IContextProviderProps } from '../../types/interfaces'
import toast from '../../components/molecules/RwToast'
import { useCookies } from 'react-cookie'
import { isProline } from '../../helpers'

const { detect } = require('detect-browser')
const browser = detect()

export const initialState: IUserState = {
  shippingAddresses: [],
  customerOpenOrders: [],
  customerCompleteOrders: [],
  customerPreviousOrders: [],
  verification_code: null,
  initialUrl: '',
  userDeviceInfo: null,
  requestFormSuccessText: null,
  isDarkMode: false,
  pagination: null,
  loadingCustomerCompleteOrders: false,
  verificationCodeRequest: null,
  resetPasswordMethods: [],
  getCustomerShippingAddresses: () => {},
  setVerificationCodeRequest: () => {},
  setVerificationCode: () => {},
  requestPasswordReset: () => {},
  resetPasswordInit: () => {},
  resetPassword: () => {},
  setRequestFormSuccessText: () => {},
  changePassword: () => {},
  getCustomerOpenOrders: () => {},
  getCustomerCompleteOrders: () => {},
  getCustomerPreviousOrders: () => {},
  setInitialUrl: () => {},
  setUserDeviceInfo: () => {},
  initializeUserState: () => {},
  getDarkMode: () => {},
  setDarkMode: () => {},
  setPage: () => {},
  setPagination: () => {},
}

export const UserContext = createContext(initialState)

export const useUserContext = () => useContext(UserContext)

export const UserProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(userReducer, initialState)
  const [loadingCustomerCompleteOrders, setLoadingCustomerCompleteOrders] =
    useState(false)

  const navigate = useNavigate()

  const [cookies, setCookie, removeCookie] = useCookies(['darkModeEnabled'])

  const setVerificationCodeRequest = async (
    verificationCodeRequest: IVerificationCodeRequest,
  ) => {
    dispatch({
      type: SET_VERIFICATION_CODE_REQUEST,
      payload: verificationCodeRequest,
    })
  }
  const setVerificationCode = async (code: string) => {
    dispatch({
      type: SET_VERIFICATION_CODE,
      payload: code,
    })
  }

  const setPage = async (page: number) => {
    dispatch({
      type: SET_PAGE,
      payload: page,
    })
  }

  const setPagination = async (pagination: IPagination) => {
    dispatch({
      type: SET_PAGINATION,
      payload: pagination,
    })
  }

  const requestPasswordReset = async (
    verificationCodeRequest: IVerificationCodeRequest | null,
  ) => {
    const response = await axios.post(`${AUTH_URL}reset-password`, {
      customers_email_address: verificationCodeRequest?.customers_email_address,
      method: verificationCodeRequest?.method,
    })

    if (!response?.data?.success) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
    } else if (response?.data?.success) {
      toast.success('Code has been sent.')
      navigate('/login/reset-password/verification')
    }
  }

  const resetPasswordInit = async (email: string) => {
    const response = await axios.post(`${AUTH_URL}reset-password-init`, {
      customers_email_address: email,
    })

    if (!response?.data?.success) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
    } else if (response?.data?.success) {
      dispatch({
        type: GET_RESET_PASSWORD_METHODS,
        payload: response.data.data,
      })

      navigate('/login/reset-password-method')
    }
  }

  const resetPassword = async (resetPasswordData: IResetPasswordData) => {
    const response = await axios.post(
      `${AUTH_URL}reset-password-change`,
      resetPasswordData,
    )

    if (!response?.data?.success) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
    } else if (response?.data?.success) {
      toast.success('Password reset complete.')
      navigate('/login')
    }
  }
  useEffect(() => {
    if (browser) {
      setUserDeviceInfo(browser)
    } // needs detect-browser library (above)
  }, [])

  const setRequestFormSuccessText = async (successText: any) => {
    dispatch({
      type: SET_REQUEST_FORM_SUCCESS_TEXT,
      payload: successText,
    })
  }

  const changePassword = async (
    changePasswordData: IChangePasswordData,
    resetForm: Function,
  ) => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }

    const response = await axios.post(
      `${AUTH_URL}change-password`,
      changePasswordData,
      headersValue,
    )

    if (!response?.data?.success) {
      toast.error(`${response.data.errors.join(' ') || 'An error occurred.'}`)
    } else if (response?.data?.success) {
      toast.success('Password changed.')
      resetForm()
    }
  }

  const getCustomerShippingAddresses = async () => {
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.get(
      `${API_URL}carts/select-cart-shipping-addresses`,
      headersValue,
    )

    dispatch({
      type: GET_CUSTOMER_SHIPPING_ADDRESSES,
      payload: response.data,
    })
  }

  const getCustomerOpenOrders = async (
    limit?: number,
    statuses?: Array<number>,
  ) => {
    const selectStatuses = statuses ? statuses : orderStatusTypeOpenIds
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${API_CUSTOMERS_URL}select-orders`,
      {
        limit: limit,
        statuses: selectStatuses,
      },
      headersValue,
    )
    dispatch({
      type: GET_CUSTOMER_OPEN_ORDERS,
      payload: response.data,
    })
  }

  const getCustomerCompleteOrders = async (
    items_per_page: number,
    page?: number,
    limit?: number,
    statuses?: Array<number>,
  ) => {
    try {
      setLoadingCustomerCompleteOrders(true)
      const selectStatuses = statuses ? statuses : orderStatusTypeCompleteIds
      const headersValue: AxiosRequestConfig = { headers: authHeader() }
      const response = await axios.post(
        `${API_CUSTOMERS_URL}select-orders`,
        {
          items_per_page: items_per_page,
          page: page,
          limit: limit,
          statuses: selectStatuses,
        },
        headersValue,
      )
      dispatch({
        type: GET_CUSTOMER_COMPLETE_ORDERS,
        payload: response.data.orders,
      })
      setPagination(response.data.meta.pagination)
    } catch (error) {
    } finally {
      setLoadingCustomerCompleteOrders(false)
    }
  }

  const getCustomerPreviousOrders = async (
    limit?: number,
    statuses?: Array<number>,
  ) => {
    const selectStatuses = statuses ? statuses : orderStatusTypePreviousIds
    const headersValue: AxiosRequestConfig = { headers: authHeader() }
    const response = await axios.post(
      `${API_CUSTOMERS_URL}select-orders`,
      {
        limit: limit,
        statuses: selectStatuses,
      },
      headersValue,
    )
    dispatch({
      type: GET_CUSTOMER_PREVIOUS_ORDERS,
      payload: response.data,
    })
  }

  const setInitialUrl = async (url?: any) => {
    dispatch({
      type: SET_INITIAL_URL,
      payload: url,
    })
  }
  const setUserDeviceInfo = async (deviceInfo: object) => {
    dispatch({
      type: SET_USER_DEVICE_INFO,
      payload: deviceInfo,
    })
  }

  const initializeUserState = async () => {
    dispatch({
      type: INITIALIZE_USER_STATE,
      payload: null,
    })
    getDarkMode()
  }

  const getDarkMode = () => {
    const isDarkMode = cookies.darkModeEnabled
    if (isDarkMode === undefined) {
      return setDarkMode(isProline() ? true : false)
    }
    setDarkMode(isDarkMode)
  }

  const setDarkMode = (isDarkMode: boolean) => {
    setCookie('darkModeEnabled', isDarkMode)
    if (isDarkMode) {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
    } else {
      document.documentElement.setAttribute('data-bs-theme', 'light')
    }
    dispatch({
      type: SET_DARK_MODE_ENABLED,
      payload: isDarkMode,
    })
  }

  const value = {
    ...state,
    loadingCustomerCompleteOrders,
    requestPasswordReset,
    resetPasswordInit,
    resetPassword,
    setRequestFormSuccessText,
    changePassword,
    getCustomerShippingAddresses,
    getCustomerOpenOrders,
    getCustomerCompleteOrders,
    getCustomerPreviousOrders,
    setVerificationCodeRequest,
    setVerificationCode,
    setInitialUrl,
    setUserDeviceInfo,
    initializeUserState,
    getDarkMode,
    setDarkMode,
    setPage,
    setPagination,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
