export const userKeys = {
  user: ['user'] as const,
  customer: () => ['customer'] as const,
  status: () => ['status'] as const, // note: 'status' is a '76 const for indicating user is logged in, not react-query's query status
  userProfile: () => ['user_profile'] as const,
  getCustomerPreviousOrders: ['getCustomerPreviousOrders'] as const,
  loadAvailablePickupDates: ['availablePickupDates'] as const,
  loadAvailablePickupTimes: ['availablePickupTimes'] as const,
}
// note: was modeled after keyFactory suggestions, but then simplified. could remove functions, but kept for future expansion
