import React, { useEffect } from 'react'
import { useProductContext } from '../../../context/products/ProductProvider'
import { generalBsBreakpoint } from '../../../constants/constants'
import HeaderSpacer from './HeaderSpacer'
import HeaderSpacerMobile from './HeaderSpacerMobile'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { isMobile } from '../../atoms/responsive'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ICustomerData } from '../../../queries/user/types'
import { userKeys } from '../../../queries/user/keyFactory'
import { useUserContext } from '../../../context/user/UserProvider'
import MobileMainHeaderDiv from './MobileMainHeaderDiv'
import DesktopMainHeaderDiv from './DesktopMainHeaderDiv'
import { isProline } from '../../../helpers'
import { logoutUser } from '../../../queries/user'
import { useNavigate } from 'react-router-dom'

const headerNavBarBreakpoint = generalBsBreakpoint

const HeaderNavBar: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const customer = queryClient.getQueryData(
    userKeys.customer(),
  ) as ICustomerData

  const { initializeUserState, getDarkMode, isDarkMode } = useUserContext()
  const { getCartSummary } = useProductContext()
  const { initializeProductState, getCart } = useProductContext()
  const { getDesktopMenuItems, getHamburgerMenuItems } = useSiteContext()

  useEffect(() => {
    !isMobile && getDesktopMenuItems()
    getHamburgerMenuItems()
    getDarkMode()
  }, [])

  useEffect(() => {
    if (customer) {
      getCartSummary()
      getCart()
    }
  }, [customer])

  const logoutUserMutation = useMutation(logoutUser, {
    onSettled: () => {
      initializeUserState()
      initializeProductState()
      navigate('/')
    },
  })

  const logout = () => {
    logoutUserMutation.mutate({ queryClient })
  }

  const brandLogoPath = isProline()
    ? isDarkMode
      ? '_images/pro-line-header-logo-dm-red.png'
      : '_images/pro-line-header-logo.png'
    : '_images/LOGO-SO76-002B-2022-compressed-cropped.png'

  return (
    <>
      {/* MOBILE */}
      <div className={`d-${headerNavBarBreakpoint}-none`}>
        <HeaderSpacerMobile />
        <MobileMainHeaderDiv
          brandLogoPath={brandLogoPath}
          handleLogout={logout}
        />
      </div>

      {/* DESKTOP */}
      <div className={`d-none d-${headerNavBarBreakpoint}-block`}>
        <HeaderSpacer />
        <DesktopMainHeaderDiv
          brandLogoPath={brandLogoPath}
          handleLogout={logout}
        />
      </div>
    </>
  )
}

export default HeaderNavBar
