import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import RwIcon from '../../molecules/RwIcon'
import { SHOP_LIST_PATH } from '../../../constants/constants'
import { IHamburgerMenuItems } from '../../../context/site/types'
import SideNavMenuItems from '../../molecules/Shop/SideNavMenuItems'
import WholesaleInfo from '../../molecules/Site/WholesaleInfo'
import { useUserContext } from '../../../context/user/UserProvider'
import { BsFillMoonFill, BsSunFill } from 'react-icons/bs'
import { isProline } from '../../../helpers'
import { useProductContext } from '../../../context/products/ProductProvider'
import history from '../../../context/history'

interface ISideNavMenuProps {
  hamburgerMenuItems: IHamburgerMenuItems | null
}

const SideNavMenu: React.FC<ISideNavMenuProps> = ({ hamburgerMenuItems }) => {
  const [showSide, setShowSide] = React.useState<boolean>(false)
  const { isDarkMode, setDarkMode } = useUserContext()
  const handleClose = () => setShowSide(false)
  const handleShow = () => setShowSide(true)
  const { setPage } = useProductContext()

  const onClickQuickLink = (to: string) => {
    setPage(1)
    history.push(to)

    handleClose()
  }

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode)
  }

  return (
    <div>
      <div className="hamburger-menu-button">
        <Button
          variant={isDarkMode ? '' : 'white'}
          onClick={handleShow}
          className="button_text_bottom no-focus-dropdown-button"
        >
          <div
            className={`d-flex align-items-center pb-1 ${
              isDarkMode ? '' : 'text-primary'
            }`}
          >
            <div className="pe-" style={{ lineHeight: '10px' }}>
              <RwIcon icon="HamburgerIcon" />
            </div>
            <div className="ms-1">Shop All</div>
          </div>
        </Button>
      </div>

      <Offcanvas show={showSide} onHide={handleClose} scroll={true}>
        <Offcanvas.Header closeButton className="border-bottom border-1">
          <Offcanvas.Title>
            <Link
              to={`/${SHOP_LIST_PATH}`}
              id={`/${SHOP_LIST_PATH}`}
              onClick={() => {
                onClickQuickLink(`/${SHOP_LIST_PATH}`)
              }}
              className="text-decoration-none"
            >
              <div
                className={`pt-2 ms-2 ps-2 fs-5 fw-bold ${
                  isDarkMode ? 'text-light' : 'text-black'
                }`}
              >
                Wholesale Fireworks
              </div>
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <SideNavMenuItems handleClick={handleClose} />
          {isProline() ? (
            <Button
              variant={isDarkMode ? 'secondary' : 'primary'}
              onClick={toggleDarkMode}
              className="ms-3 mt-3"
            >
              <span>{isDarkMode ? <BsSunFill /> : <BsFillMoonFill />}</span>
              <span className={'ms-2'}>
                {isDarkMode ? 'Disable ' : 'Enable'} Dark Mode
              </span>
            </Button>
          ) : (
            <></>
          )}
          <WholesaleInfo />
          <div className="ps-3 mt-4">
            <Link
              to={isProline() ? '/contact' : '//76fireworks.com/contact'}
              target={isProline() ? '_self' : '_blank'}
              onClick={handleClose}
            >
              Contact Us
            </Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default SideNavMenu
