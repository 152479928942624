export const GET_CUSTOMER_SHIPPING_ADDRESSES = 'GET_CUSTOMER_SHIPPING_ADDRESSES'
export const GET_CUSTOMER_OPEN_ORDERS = 'GET_CUSTOMER_OPEN_ORDERS'
export const GET_CUSTOMER_OPEN_ORDERS_LANDING =
  'GET_CUSTOMER_OPEN_ORDERS_LANDING'

export const GET_CUSTOMER_COMPLETE_ORDERS = 'GET_CUSTOMER_COMPLETE_ORDERS'
export const GET_CUSTOMER_PREVIOUS_ORDERS = 'GET_CUSTOMER_PREVIOUS_ORDERS'
export const SET_INITIAL_URL = 'SET_INITIAL_URL'
export const SET_USER_DEVICE_INFO = 'SET_USER_DEVICE_INFO'
export const SET_VERIFICATION_CODE = 'SET_VERIFICATION_CODE'
export const INITIALIZE_USER_STATE = 'INITIALIZE_USER_STATE'
export const SET_REQUEST_FORM_SUCCESS_TEXT = 'SET_REQUEST_FORM_SUCCESS_TEXT'
export const SET_DARK_MODE_ENABLED = 'SET_DARK_MODE_ENABLED'
export const SET_PAGE = 'SET_PAGE'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_VERIFICATION_CODE_REQUEST = 'SET_VERIFICATION_CODE_REQUEST'
export const GET_RESET_PASSWORD_METHODS = 'GET_RESET_PASSWORD_METHODS'
