import React from 'react'
import { Link } from 'react-router-dom'
import CdnImg from '../CdnImg'
import Nav from 'react-bootstrap/Nav'
import SocialMediaIcons from '../../../molecules/SocialMediaIcons'
import { Desktop, Mobile } from '../../../atoms/responsive'
import { currentYear } from '../../../../constants/constants'
import { useSiteContext } from '../../../../context/site/SiteProvider'

const SpiritFooter: React.FC = () => {
  const { storeSettings, appSettings } = useSiteContext()
  return (
    <>
      <footer className="bg-primary p-3">
        <Mobile>
          <SocialMediaIcons className="d-flex align-items-center justify-content-center text-center pb-3" />
        </Mobile>
        <div className="d-flex">
          <div className="img-fluid max-width 20% me-auto">
            <CdnImg
              path="_store/mail/images/logo_2022.png"
              alt="logo_new_white"
              width="125"
            />
          </div>
          <Desktop>
            <SocialMediaIcons className="mx-auto" />
          </Desktop>
          <div className="ms-auto pe-2 text-white text-end">
            {appSettings?.aws_public_files_path &&
            storeSettings?.privacy_policy_filename ? (
              <Nav className=" justify-content-end">
                <Nav.Item className="pe-1">
                  <Link
                    to={`//forms.dailystory.com/webform/hosted/2p01n`}
                    className="text-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Subscribe to Receive Discounts
                  </Link>
                </Nav.Item>
                <Nav.Item className="pe-1"> · </Nav.Item>
                <Nav.Item className="pe-1">
                  <Link
                    to={`//76fireworks.com/contact`}
                    className="text-white"
                    target="_blank"
                  >
                    Contact Us
                  </Link>
                </Nav.Item>
                <Nav.Item className="pe-1"> · </Nav.Item>
                <Nav.Item className="pe-1">
                  <a
                    href={
                      appSettings.aws_public_files_path +
                      storeSettings.terms_of_use_filename
                    }
                    target="_blank"
                    className="text-white"
                  >
                    Terms of Use
                  </a>
                </Nav.Item>
                <Nav.Item className="pe-1"> · </Nav.Item>
                <Nav.Item>
                  <a
                    href={
                      appSettings.aws_public_files_path +
                      storeSettings.privacy_policy_filename
                    }
                    target="_blank"
                    className="text-white"
                  >
                    Privacy Policy
                  </a>
                </Nav.Item>
              </Nav>
            ) : (
              <></>
            )}
            <div className="mb-0 mt-1 text-light">
              <small>&copy; {currentYear} Spirit of '76 Fireworks</small>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default SpiritFooter
