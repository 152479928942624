import './App.scss'
import './scss/custom.scss'
import 'react-toastify/dist/ReactToastify.css'
import Routes from './routes'
import { BrowserRouter } from 'react-router-dom'
import Header from './components/organisms/Header'
import Footer from './components/organisms/Common/Footer'

import { UserProvider } from './context/user/UserProvider'
import { ProductProvider } from './context/products/ProductProvider'
import { SiteProvider } from './context/site/SiteProvider'
import { ShowHideProvider } from './context/showhide/ShowHideProvider'
import { ToastContainer } from 'react-toastify'
import { Flip } from 'react-toastify'
import ModalDeadFish from './components/organisms/Common/Modal/ModalDeadFish'
import { CookiesProvider } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { isProline } from './helpers'
import {
  DEFAULT_PL_META_DESCRIPTION,
  DEFAULT_PL_TITLE,
  DEFAULT_WS_META_DESCRIPTION,
  DEFAULT_WS_TITLE,
} from './constants/constants'

const metaDescription = isProline()
  ? DEFAULT_PL_META_DESCRIPTION
  : DEFAULT_WS_META_DESCRIPTION

const title = isProline() ? DEFAULT_PL_TITLE : DEFAULT_WS_TITLE

const App = () => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <BrowserRouter>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <ShowHideProvider>
            <UserProvider>
              <ProductProvider>
                <SiteProvider>
                  <div className="page_container">
                    <Header />
                    <Routes />
                    <ModalDeadFish />
                    <ToastContainer
                      transition={Flip}
                      theme="colored"
                      autoClose={3000}
                    />
                  </div>
                  <Footer />
                </SiteProvider>
              </ProductProvider>
            </UserProvider>
          </ShowHideProvider>
        </CookiesProvider>
      </BrowserRouter>
    </>
  )
}

export default App
