import React from 'react'
import { BsX } from 'react-icons/bs'
import { CDN_URI } from '../../../../constants/cdn'
import { IFlashSaleBanner } from '../../../../context/site/types'

interface IFlashSaleBannerContent {
  banner: IFlashSaleBanner | null
  handleClick: () => void
}

const FlashSaleBannerContent: React.FC<IFlashSaleBannerContent> = ({
  banner,
  handleClick,
}) => {
  if (!banner) {
    return <></>
  }
  const bannerDivStyle = {
    backgroundColor: banner.background_color,
    backgroundImage: banner.background_image_path
      ? `url(${CDN_URI}${banner.background_image_path})`
      : '',
    backgroundPosition: banner.background_position,
    backgroundSize: banner.background_size,
    backgroundRepeat: banner.background_repeat,
  }

  return (
    <div style={bannerDivStyle} className="w-100 my-0 ps-2 d-flex">
      <div
        className="w-100"
        dangerouslySetInnerHTML={{
          __html: banner?.html || '',
        }}
      />
      {banner?.is_closable ? (
        <div className="cursor_pointer me-2" onClick={handleClick}>
          <BsX className="fs-2 text-primary mt-2" />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default FlashSaleBannerContent
