export const GET_DESKTOP_MENU_ITEMS = 'GET_DESKTOP_MENU_ITEMS'
export const GET_HAMBURGER_MENU_ITEMS = 'GET_HAMBURGER_MENU_ITEMS'
export const GET_STORE_SETTINGS = 'GET_STORE_SETTINGS'
export const GET_APP_INFO = 'GET_APP_INFO'
export const SET_APP_VERSION_CURRENT = 'SET_APP_VERSION_CURRENT'
export const GET_AUTHORIZATION_DOC_TYPES = 'GET_AUTHORIZATION_DOC_TYPES'
export const GET_INSURANCE_DOC_TYPES = 'GET_INSURANCE_DOC_TYPES'
export const GET_APP_SETTINGS = 'GET_APP_SETTINGS'
export const TOGGLE_PRODUCT_LIST_DETAILS = 'TOGGLE_PRODUCT_LIST_DETAILS'
export const SET_MAIN_HEADER_DIV_HEIGHT = 'SET_MAIN_HEADER_DIV_HEIGHT'
export const SET_MOBILE_MAIN_HEADER_DIV_HEIGHT =
  'SET_MOBILE_MAIN_HEADER_DIV_HEIGHT'
export const SET_FLASH_SALE_BANNER_DESKTOP = 'SET_FLASH_SALE_BANNER_DESKTOP'
export const SET_FLASH_SALE_BANNER_MOBILE = 'SET_FLASH_SALE_BANNER_MOBILE'
export const SET_SHOW_FLASH_SALE_BANNER = 'SET_SHOW_FLASH_SALE_BANNER'
export const SET_IS_DOWN_FOR_MAINTENANCE = 'SET_IS_DOWN_FOR_MAINTENANCE'
